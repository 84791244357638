.graphContainer {
  width: 14vw;
  margin-left: 20px;
  margin-right: 20px;
}

.tooltipContainer {
  background-color: white;
  border-color: black;
  border-width: 2px;
}
